/* my-loader css */
.my_Loader {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: wait;
  overflow: hidden;
  z-index: 99999;
  position: fixed;
  /* background: #fff; */
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
.load_content {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
}
.loadText {
  text-align: center;
  font-style: italic;
  margin-top: 1em;
  text-shadow: -1px 1px 3px #808080d1;
}
.loading {
  height: 0;
  width: 0;
  padding: 20px;
  border: 4px solid #f1f1f1;
  border-right-color: #ff9800;
  border-top-color: #ff9800;
  border-radius: 50px;
  box-shadow: 0px 0px 2px #808080d1;
  -webkit-animation: rotate 0.75s infinite linear;
  animation: rotate 0.75s infinite linear;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
