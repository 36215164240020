.selected_cursor {
    cursor: pointer;
    border: 4px solid #3e30ff!important;
    box-shadow: 0 0 10px -2px #3e30ff86;
}
.design_div {
    position: relative;
    border: 4px solid #fff;
}
.design_div:hover {
    cursor: pointer;
    border: 4px solid #3e30ff;
}
.dsg_tltip {
    display: none;
    top: auto;
    left: 0;
    bottom: 86%;
    width: 100%;
    color: #fff;
    z-index: 102;
    padding: 5px 10px;
    line-height: 1.4;
    font-size: 15px;
    position: absolute;
    border-radius: 10px;
    background: #000000eb;
    transition: 0.3s ease-in-out;
    transform: translate(0px, -72px);
    box-shadow: 0 3px 10px 2px #4a45455e;
}
.design_div:hover .dsg_tltip {
    display: block;
    transition: 0.3s ease-in-out;
}
.dsg_tltip:after {
    content: " ";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 10px;
    height: 10px;
    border: 10px solid;
    transform: translate(-50%, 100%);
    border-color: #00100f transparent transparent transparent;
}
.flexDiv {
    margin: 0 3px;
}
.flexDiv img.img_des {
    max-width: 100%;
    max-height: 520px;
    min-height: 520px;
}
.designed_area {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.designed_area .item_panel {
    position: relative;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.designed_area *::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.designed_area *::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.designed_area *::-webkit-scrollbar-thumb {
  background: #eee; 
}

/* Handle on hover */
.designed_area *::-webkit-scrollbar-thumb:hover {
  background: #ddd; 
}

.icon_contains {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 4px;
    width: 68%;
    height: auto;
}
.icon_contains .icon_div {
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 4px;
    background: #6272ee;
    margin: 0px 5px 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.icon_contains .icon_item {
    color: #fff;
    width: 35px;
    height: 35px;
    font-size: 30px;
    line-height: 35px;
}
.panel_item {
    width: 100%;
    height: 210px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.panel_item .text_block>h5 {
    position: absolute;
}
.left_aside_icon {
    overflow: auto !important;
    width: 100px;
    max-width: 25%;
    padding: 10px 7px;
    background: rgb(0 0 0 / 16%);
    box-shadow: 2px 0 4px rgb(0 0 0 / 10%);
}

.right_aside_panel {
    width: 75%;
    overflow-y: auto!important;
}

input.page_input_file {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.custom_file_input {
    position: absolute;
    top: 5px;
    width: 100%;
    border: 1px solid #6d6d6d;
    background: #eff2f7;
}
.add2Icons {
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 60%;
    border: 1px solid #5c5c5c;
    background: #acacac;
}
.ck.ck-editor__main .ck-content.ck-editor__editable{
    height: 250px;
    overflow-y: auto;
}