.imageCrop {
    position: relative;
    width: 100%;
    height: 200px;
}
.imgPrev {
    position: absolute;
    right: 0;
    width: 50%;
    height: 200px;
    padding-left: 1em;
}
.imgPrev>img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.reactEasyCrop_Container {
    width: 50%;
    height: 200px;
}