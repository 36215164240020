*:focus {
    outline: none;
}
.text-black {
    color: #000;
}
.cursor {
    cursor: pointer;
}
.ecomPreview {
    background: #ffffff;
    border-radius: 20px;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 3em;
}
.scroll_here {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.ecomPreview button:disabled , .ecomPreview button:disabled i {
    cursor: no-drop;
    color: rgb(187, 99, 99);
    opacity: 0.7;
}
.ecomPreview  h5, .ecomPreview h6 {
    font-family: serif;
}
.ecomPreview .navKyc {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.ecomPreview .navKyc  * {
    font-size: 16px;
    color: #41c4d9;
    font-weight: 700;
}
.ecomPreview .navKyc .cart_btn>i {
    font-size: 22px;
}
.ecomPreview .navKyc .cart_btn>span{
    padding: 0;
    font-size: 9px;
    color: #fff;
    width: 14px;
    height: 14px;
    z-index: 999;
    border-radius: 50%;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    background: red;
    position: absolute;
}
.ecomPreview .productLayout img.img-fluid {
    width: 114px;
    height: 114px;
    margin: auto;
    margin-bottom: 10px;
    /* background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 5px #00000026; */
    transition: 0.3s ease-in-out;
}
.ecomPreview  .product_item {
    width: 47%;
    margin: auto;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding: 4px 7px;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 1.75em;
    box-shadow: 0 1px 5px -1px #00000026;
}
.ecomPreview .productLayout h6 {
    color: #2b2b2b;
    font-family: serif;
    font-weight: 700;
    /* font-size: 16px; */
}
.ecomPreview .productLayout .price {
    color: #41c4d9;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.ecomPreview  .product_item:hover img.img-fluid {
    transform: scale(1.1);
    transition: 0.3s ease-in-out;
}
.product_area .item {
    border-radius: 10px;
    height: 150px;
    width: 100%;
    /* padding: 15px; */
}
.product_area .item img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.slick-dots li {
    margin: 0px;
}
.slick-slide {
    padding: 15px;
}
.slick-dots {
    bottom: -10px;
}
.single_Slide .slick-slide img {
    display: block;
    height: 100%;
    margin: auto;
    max-height: 210px;
}
.single_Slide .slick-prev, .single_Slide .slick-next {
    z-index: 999;
}
.single_Slide .slick-prev {
    left: 0;
}
.single_Slide .slick-next {
    right: 0;
}
.single_Slide .slick-prev:before, .single_Slide .slick-next:before {
    opacity: .75;
    color: #46c5da;
}