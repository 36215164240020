.modal {
  backdrop-filter: blur(15px);
}
.modal-content {
  box-shadow: 0 1px 7px -1px #252b3b3b;
}
.relative {
  position: relative;
}
.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.title-editor {
  background: #00c8c0 !important;
  color: white !important;
  border-bottom: 2px solid #006d71 !important;
}
.display_tooltip:hover {
  cursor: pointer;
}
.actionBox {
  height: calc(90vh - 50px);
  background: #7b7b7b;
  border: 1px dotted #cecece;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.btn-full-width {
  width: 100%;
}

/* upload image design */
.upload_image_area {
  height: 250px;
  position: relative;
}
.upload_image_area .load_form {
  position: relative;
  width: 100%;
  height: 200px;
  border: 4px dashed #00c8bf;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.upload_image_area .load_form p {
  width: 100%;
  height: 100%;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  line-height: 10;
  color: #00c8bf;
  font-family: Arial;
  text-shadow: 1px 2px 2px #000000;
}
.upload_image_area .load_form input[type="file"] {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.upload_image_area .load_form button {
  margin: 0;
  color: #fff;
  background: #00c8bf;
  border: none;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  border-bottom: 4px solid #03b4ab;
  transition: all 0.2s ease;
  outline: none;
}
.upload_image_area .load_form button:hover {
  background: #03b4ab;
  color: #0c5645;
}
.upload_image_area .load_form button:active {
  border: 0;
}
.iconsItem {
  width: 65px;
  height: 50px;
  display: flex;
  margin: 5px 0;
  margin-right: 5px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.iconsItem .grav_icon {
  width: 35px;
  height: 35px;
  font-size: 1.65em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 50%;
  color: #00c8bf;
  margin-bottom: 10px;
}
.customUploadBtn {
  position: relative;
}

.customUploadBtn input.upload_input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.home_logo {
  margin: auto;
}
/* .home_logo>img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
} */

.form-control.is_valid {
  /* border-color: #00ff55; */
  padding-right: calc(1.5em + .94rem);
  background-image: url(../../assets/images/tik.png);
  background-repeat: no-repeat;
  background-position: right calc(.375em + .235rem) center;
  background-size: calc(.75em + .35rem) calc(.75em + .35rem);
}
.back_layout {
  background-image: url(../../assets/images/image-section.png);
  opacity: .5;
  height: 100%;
  width: 100%;
  position: absolute;
}
.back_layImg {
  position: absolute;
  right: -10%;
  bottom: -13%;
  width: 435px;
  height: 300px;
}
.back_overlay {
  height: 450px;
  background-color: transparent;
  background-image: linear-gradient(180deg,#0046a0 0%,#0458e9 100%);
}
.back_overlay > button.close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 22px;
  height: 22px;
  color: #fff;
  opacity: 1;
  z-index: 9;
  display: flex;
  border-radius: 50%;
  background: red;
  justify-content: center;
  box-shadow: -2px 2px 15px 0px #fff;
}
.back_overlay .spt_title {
  color: #ffa824;
  font-weight: 600;
  letter-spacing: 0.09px;
  text-shadow: 1px 1px 3px #000000db;
}