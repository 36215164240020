.faqs {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0px;
}

.faqs .faq {
  margin: 10px;
  padding: 10px;
  margin-bottom: 1em;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #f9f9f9;
  box-shadow: none;
}

.faqs .faq .faq-question {
  position: relative;
  color: #1e1e1e;
  font-size: 17px;
  cursor: pointer;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url("../../assets/images/arrow-down-mint.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
