
.loader {
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: auto;
    display:table;
    margin-top: 150px;
}
.react-tags__search-input {
    width: 1000px;
    padding: 10px;
}